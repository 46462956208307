
















import { Vue, Component, Prop } from "vue-property-decorator";
import { BCard, BCardBody, BAvatar } from "bootstrap-vue";

@Component({
    name: "alt-statistic-card",
    components: {
        BCard,
        BCardBody,
        BAvatar,
    },
})
export default class AltStatisticCard extends Vue {
    @Prop({ type: String, required: true })
    private icon!: string;

    @Prop({ type: [Number, String], required: true })
    private statistic!: number | string;

    @Prop({ type: String, default: "" })
    private statisticTitle!: string;

    @Prop({ type: String, default: "primary" })
    private color!: string;
}
